<template>
  <div>
    <div :class="`${node.type == 'group' ? 'node-hover' : ''}`">
      <div
          :style="{'margin-left': 25 * depth + 'px'}"
      >
        <div class="d-flex justify-content-between">
          <div class="node" @click="expanded = !expanded">
            <span v-if="hasChildren">{{ expanded ? '&#9660' : '&#9658' }}</span>
            <span v-if="node.type == 'group'">{{ node.name }}</span>
          </div>
        </div>
        <div
            class="d-flex cursor-initial justify-content-between bg-black-light rounded-8 p-1 mb-1"
            v-if="node.type == 'endpoint'"
        >
          <p class="mb-0">{{ node.name }}</p>
          <div class="d-flex">
            <p class="mb-0 me-1">{{ budgetGeneral?.amount }}</p>
            <img @click="handleBudgetGeneral(node, budgetGeneral)"
                 width="13"
                 class="cursor-pointer"
                 src="/app-assets/images/icons/pencil-alt-solid.svg"
                 alt=""
            >
          </div>
        </div>
      </div>
    </div>
    <hr class="mb-1 mt-0" v-if="type == 'group'">
    <template v-if="expanded">
      <TreeBrowser
          v-for="item in node.child_with_budget"
          :key="item.name"
          :parent="node"
          :depth="depth + 1"
          :node="item"
      />
    </template>
  </div>
</template>

<script setup>
import {computed, inject, ref} from 'vue';
import {useRoute} from "vue-router";

const {node, depth, parent} = defineProps(['node', 'depth', 'parent']);
const emitter = inject('emitter');
const $route = useRoute()

let expanded = ref(true);
let budget_general = ref(node?.budget_general)
let budgetId = $route.params.budgetId;

const handleBudgetGeneral = (data, budgetGeneral) => {
  emitter.emit('onBudgetGeneralEdit', {
    eventName: 'onBudgetGeneralEdit',
    title: 'Edit budget general',
    data: {
      data : data,
      budgetGeneral: budgetGeneral
    }
  })
}

const budgetGeneral = computed( () => {
  let bg = {};
  if (budget_general.value){
    bg = budget_general.value.filter( $bg => {
      return ($bg.budget_master_id === parseInt(budgetId));
    })
  }
  return bg[0];
})

const hasChildren = computed(() => {
  return node.child_with_budget.length;
});

const type = computed(() => {
  return node.type;
});

const parentL = computed(() => {
  return parent.child_with_budget?.length ? parent.child_with_budget?.length : 0;
});

const last = computed(() => {
  return parent.child_with_budget ? parent.child_with_budget[parentL - 1] : {};
});

</script>


<style scoped>
.bg-black-light {
  background: #00000024;
}

.rounded-8 {
  border-radius: 8px;
}

.node {
  cursor: pointer;
}

.node-hover {
  padding: 10px 2px;
}

.node-hover:hover {
  background: #fffeec;
}

.cursor-initial {
  cursor: initial;
}

.cursor-pointer {
  cursor: pointer;
}

.w-30 {
  width: 30px;
}

.w-20 {
  width: 20px;
}

.z-999 {
  z-index: 999;
}

.w-44 {
  width: 44px;
}
</style>