<template>
  <div class="card">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          :title="(budgetId ? 'Edit' : 'Add New') + ' Budget'"
          @onClickCloseButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-3">
            <label class="align-middle">Project</label>
            <v-select
              placeholder="Select Project"
              v-model="formData.project_master_id"
              :options="projects"
              label="name"
              :reduce="name => name.id"
              @option:selected="onProjectSelected"
            />
          </div>
          <div class="col-sm-3">
            <label class="align-middle">Interval</label>
            <input type="text" class="form-control disabled" name="interval" :value="projectInterval?.name" disabled/>
          </div>
          <div class="col-sm-3">
            <label class="align-middle">Start Date</label>
            <input type="date" class="form-control disabled" :value="selectedProject?.start_date" disabled>
          </div>
          <div class="col-sm-3">
            <label class="align-middle">End Date</label>
            <input type="date" class="form-control disabled" :value="selectedProject?.end_date" disabled>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-6">
            <label class="align-middle">Budget Name</label>
            <input
                placeholder="Budget name"
                v-model="formData.name"
                class="form-control"
            />
          </div>
          <div class="col-sm-3">
            <label class="align-middle">Budget Code</label>
            <input type="text" class="form-control" v-model="formData.code">
          </div>
          <div class="col-sm-3">
            <label class="align-middle">Create Date</label>
            <input type="date" class="form-control" v-model="formData.create_date">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-6">
            <label class="align-middle">Business</label>
            <v-select
                placeholder="Select Business"
                v-model="formData.business_id"
                :options="businesses"
                label="name"
                :reduce="name => name.id"
            />
          </div>
          <div class="col-sm-3">
            <label class="align-middle">Cost Centre</label>
            <v-select
                placeholder="Select Cost Centre"
                v-model="formData.cost_centre_id"
                :options="costCentres"
                label="name"
                :reduce="name => name.id"
            />
          </div>
          <div class="col-sm-3">
            <label class="align-middle">Party</label>
            <v-select
                placeholder="Select Party"
                v-model="formData.party_id"
                :options="parties"
                label="name"
                :reduce="name => name.id"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white p-1" v-show="budgetId">

      <AccountTree
          :accounts="accounts"
          :tabs="homeContent.project_account_type"
          @handleTabChange="handleTabChange"
      />
    </div>

    <div class="bg-blue-light p-1">
      <div class="mt-2">
        <div class="d-flex flex-wrap gap-1 gy-2 justify-content-center">
          <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
                  class="btn btn-primary">
            Save
          </button>
          <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
                  class="btn btn-primary">Save & New
          </button>
          <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
        </div>
      </div>
    </div>

    <GlobalLoader/>

    <BudgetGeneralModal
        ref="budgetGeneralModal"
        :form="budgetGeneralForm"
        @onBusinessGeneralSave="handleBudgetGeneral"
        @onResetBreakdown="getBudgetInterval"
    />

  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import handleCBusinesses from "@/services/modules/businesses";
import handleProjects from "@/services/modules/procurement/project";
import handleCostCentres from "@/services/modules/procurement/organization-settings/costCentre";
import loader from "@/components/atom/loader/Loader";
import handleBudgets from "@/services/modules/procurement/budget";
import AccountTree from "@/components/molecule/procurement/budget/AccountTree";
import handleInventory from "@/services/modules/inventory";
import BudgetGeneralModal from "@/components/molecule/procurement/budget/BudgetGeneralModal";

const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const emitter = inject('emitter');
const {fetchBusinessList} = handleCBusinesses();
const {fetchCostCentreList} = handleCostCentres();
const {fetchProjects, fetchParties} = handleProjects();
const {
  createNewBudget,
  updateBudget,
  fetchAccountTree,
  fetchBudget,
  fetchBudgetGeneral,
  fetchAccount,
  storeBudgetGeneral,
  updateBudgetGeneral,
  fetchNewBudgetInterval,
} = handleBudgets();
const {fetchHome} = handleInventory();

let budgetGeneralModal = ref(null)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let tab = ref('item');
let homeContent = ref({});
let businesses = ref([])
let projects = ref([])
let parties = ref([])
let costCentres = ref([])
let accounts = ref([])
let selectedProject = ref({});

let formData = ref({
  business_id: null,
  cost_centre_id: null,
  project_master_id: null,
  party_id: null,
  name: null,
  code: null,
  create_date: null,
})
let budgetGeneralForm = ref({
  'budget_master_id': '',
  'project_account_id': '',
  'project_account_name': '',
  'quantity': '',
  'rate': '',
  'amount': '',
  'fill_type': 'fill',
});

function navigateToListPage() {
  route.params.budgetId = null;
  router.push({name: `project-budget-list`, params: route.params, query: route.query});
}

function handleSubmit(redirect = false) {
  if (redirect) {
    saveButtonLoader.value = true
  } else {
    saveNewButtonLoader.value = true
  }

  if(selectedProject.value.interval === null) {
    showError('Please update project with interval');
    saveButtonLoader.value = false;
    saveNewButtonLoader.value = false;
    return;
  }

  let res = null;
  if (budgetId.value) {
    res = updateBudget(budgetId.value, formData.value, getQuery());
  } else {
    res = createNewBudget(formData.value, getQuery());
  }

  res.then(response => {
    saveButtonLoader.value = false
    saveNewButtonLoader.value = false

    if (response.status) {
      showSuccess(response.message)
      router.push({name: `project-budget-edit`, params: {...route.params, budgetId: response.data.id}, query: route.query});
      getAccountTree();
    }
    if (!response.status) {
      showError(response.message)
    }
  }).catch(err => {
    saveButtonLoader.value = false;
    saveNewButtonLoader.value = false;
  })
}

function resetForm() {
  formData.value = {
    business_id: null,
    cost_centre_id: null,
    project_master_id: null,
    party_id: null,
    name: null,
    code: null,
    create_date: null,
    start_date: null,
    end_date: null,
    interval: null,
  }
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function showBudgetGeneralModal() {
  if(budgetGeneralModal.value !== null)
    budgetGeneralModal.value.toggleModal();
}

function handleTabChange(tabName = 'item') {
  tab.value = tabName;
  getAccountTree();
}

function onProjectSelected() {
  if(formData.value.project_master_id !== null) {
    let project = projects.value.filter( (project) => {
      return (project.id === formData.value.project_master_id)
    })
    selectedProject.value = project[0] ?? {}
  } else {
    selectedProject.value = {};
  }
}

async function getBudget() {
  if (!budgetId.value) {
    return;
  }
  const res = await fetchBudget(budgetId.value, getQuery());
  if (!res.status) {
    showError(res.message);
  } else {
    formData.value = res.data;
  }
}

async function getAccountTree() {
  accounts.value = [];
  if (!budgetId.value) {
    return;
  }
  let query = getQuery();
  query += `&account_type=${tab.value.toLowerCase()}`;

  const res = await fetchAccountTree(budgetId.value, query);

  if (res.data) {
    accounts.value = res.data;
  }
}

async function getBudgetGeneral(accountId, budgetGeneral = null) {
  let res = null;
  let query = getQuery();
  if (budgetGeneral) {
    res = await fetchBudgetGeneral(budgetId.value, budgetGeneral, query);
  } else {
    res = await fetchAccount(budgetId.value, accountId, query);
  }

  if (res.status) {
    budgetGeneralForm.value = {...res.data};
  }
}

async function handleBudgetGeneral(generalId = null) {
  let res = null;

  if (generalId) {
    res = await updateBudgetGeneral(budgetId.value, generalId, budgetGeneralForm.value, getQuery());
  } else {
    res = await storeBudgetGeneral(budgetId.value, budgetGeneralForm.value, getQuery());
  }

  if (res.status) {
    showSuccess(res.message);
    await getAccountTree();
    emitter.emit('onBudgetGeneralUpdate', {
      eventName: 'onBudgetGeneralUpdate',
      title: 'Update budget general'
    })
    showBudgetGeneralModal();
  }
}

const getBudgetInterval = (data) => {
  fetchNewBudgetInterval(data, getQuery())
    .then( res => {
      if(res.status) {
        budgetGeneralForm.value.breakdown = res.data;
      } else {
        showError(res.message);
      }
    })
    .catch( err => {
      showError('Something went wrong please try again.')
    });
}

const intervals = computed(() => {
  let intervals = [];
  if(homeContent.value.hasOwnProperty('project_intervals')) {
    Object.keys(homeContent.value.project_intervals).forEach( id => {
      intervals.push({
        id: id,
        name: homeContent.value.project_intervals[id],
      })
    })
  }
  return intervals;
})

const budgetId = computed(() => {
  return route.params.budgetId ? route.params.budgetId : '';
});

const projectInterval = computed( () => {
  return intervals.value.filter( interval => {
    return (interval.id === selectedProject.value?.interval)
  })[0] ?? {};
})

onMounted(async () => {
  loader.value = true;
  const businessRes = fetchBusinessList(getQuery());
  const costCentreRes = fetchCostCentreList(getQuery());
  const partyRes = fetchParties(getQuery());
  const projectRes = fetchProjects(getQuery() + '&time_and_interval=1');
  const homeRes = fetchHome();

  await Promise.all([
    homeRes.then(res => {
      if (res.data) {
        homeContent.value = res.data;
      }
    }),
    businessRes.then(res => {
      if (res.data) {
        businesses.value = res.data;
      }
    }),
    costCentreRes.then(res => {
      if (res.data) {
        costCentres.value = res.data;
      }
    }),
    partyRes.then(res => {
      if (res.data) {
        parties.value = res.data;
      }
    }),
    await getBudget(),
    projectRes.then(res => {
      if (res.data) {
        projects.value = res.data;
        selectedProject.value = res.data.filter( (project) => {
          return (project.id === formData.value.project_master_id)
        })[0] ?? {};
      }
    }),
    getAccountTree(),
  ]).then(() => {
    loader.value = false;
  }).catch(err => {
    loader.value = false;
  });
})

emitter.on('onBudgetGeneralEdit', async ({data}) => {
  await getBudgetGeneral(data.data.id, data.budgetGeneral?.id);
  showBudgetGeneralModal();
})
</script>