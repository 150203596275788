<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
       style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ props.form?.project_account_name }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
          <form @submit.prevent="handleSubmit">
            <div class="row gy-2 px-2">

              <div class="col-3">
                <label class="form-label" for="quantity">Qty</label>
                <div>
                  <input
                      type="text"
                      name="quantity"
                      class="form-control"
                      v-model="props.form.quantity"
                      @keyup="calculateAmount"
                  >
                </div>
              </div>
              <div class="col-3">
                <label class="form-label" for="rate">Rate</label>
                <div>
                  <input
                      type="text"
                      name="rate"
                      class="form-control"
                      v-model="props.form.rate"
                      @keyup="calculateAmount"
                  >
                </div>
              </div>
              <div class="col-3">
                <label class="form-label" for="item">Amount</label>
                <div>
                  <input
                      type="text"
                      class="form-control"
                      v-model="props.form.amount"
                      @keyup="clearRate"
                  >
                </div>
              </div>
              <div class="col-3">
                <span v-if="isFillVisible">
                  <button class="btn btn-sm btn-info mt-2" type="button" @click="computeFill">
                    Fill
                  </button>
                </span>
                &nbsp;
                <span v-if="isAttributeVisible">
                  <button class="btn btn-sm btn-info mt-2" type="button" @click="computeAttribute">
                    Attribute
                  </button>
               </span>
              </div>
              <div class="col-3" v-for="(breakdown, key) in props.form.breakdown">
                <label class="form-label" for="item">{{ key }}</label>
                <div>
                  <input
                      type="text"
                      class="form-control"
                      v-model="props.form.breakdown[key]"
                      @change="sumAmount"
                      @input="hideAttribute"
                  >
                </div>
              </div>

            </div>

            <div class="col-12 text-center">
              <hr class="mt-2">
              <button :disabled="loading" type="submit"
                      class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Submit
              </button>
              <button @click.prevent="resetBreakdown" class="btn btn-outline-secondary  me-1 mt-1 waves-effect waves-float waves-light">
                Reset Breakdown
              </button>
              <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";

const props = defineProps({
  form: Object,
});
const emit = defineEmits(['onCloseModal', 'onBusinessGeneralSave', 'onResetBreakdown'])

let isOpen = ref(false);
let loading = ref(false);
let isAttributeVisible = ref(true);
let isFillVisible = ref(true);

const toggleModal = () => {
  if (isOpen.value) {
    emit("onCloseModal")
  }
  isOpen.value = !isOpen.value
}
defineExpose({toggleModal});

const handleSubmit = () => {
  emit('onBusinessGeneralSave', props.form.id);
}

const calculateAmount = () => {
  hideFill();
  props.form.amount = props.form.rate * props.form.quantity;
}

const clearRate = () => {
  hideFill();
  props.form.rate = 0;
  props.form.quantity = 0;
}

const sumAmount = () => {
  props.form.amount = 0;
  Object.values(props.form.breakdown).map(el => {
    props.form.amount += Number(el);
  })
}

const hideFill = () => {
  isAttributeVisible.value = true;
  isFillVisible.value = props.form.amount <= 0;
}

const hideAttribute = (value) => {
  isFillVisible.value = true;
  isAttributeVisible.value = value <= 0;
}

const computeFill = () => {
  props.form.fill_type = 'fill';

  let totalAmount = 0;
  const value = Object.values(props.form.breakdown)[0];

  Object.keys(props.form.breakdown).map(key => {
    props.form.breakdown[key] = value;
    totalAmount = Number(totalAmount) + Number(value);
  });

  props.form.amount = totalAmount;
  props.form.rate = 0;
  props.form.quantity = 0;
}

const computeAttribute = () => {
  props.form.fill_type = 'attribute';
  const value = (props.form.amount / Object.keys(props.form.breakdown).length).toFixed(2);

  Object.keys(props.form.breakdown).map(key => {
    props.form.breakdown[key] = value;
  });
}

const resetBreakdown = () => {
  emit('onResetBreakdown', props.form.budget_master_id)
}
</script>