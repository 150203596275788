import Network from "@/services/network";
import {ref} from "vue";

export default function handleCostCentres() {
    const network = new Network;
    const loading = ref(false);
    const uri = '/procurement/organization-setting/cost-centre';

    const fetchCostCentreList = (query) => {
        return network.api('get', uri + query);
    }

    const createNewCostCentre = (data) => {
        return network.api('post', uri, data);
    }

    const updateCostCentre = (id, data) => {
        return network.api('put', `${uri}/${id}`, data);
    }

    return {
        loading,
        fetchCostCentreList,
        createNewCostCentre,
        updateCostCentre,
    }
}