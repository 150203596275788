<template>
  <div class="px-1 rounded pb-2">
    <TitleButton
        class="mt-1"
        :showBtn="false"
        :showAddNew="false"
        title="Budget Setup"
    >
    </TitleButton>

    <div class="row gy-1">
      <section id="basic-tabs-components">
        <div class="row match-height">
          <div class="col-12">
            <ul class="nav nav-tabs" role="tablist">
              <li
                  class="nav-item"
                  :class="`${accountType == i ? 'active' : ''}`"
                  v-for="(tab, i) in tabs"
                  :key="i"
                  @click="onClickTab(i)"
                  type="button"
              >
                <a :class="`${accountType == i ? 'active' : ''}`" class="nav-link" data-bs-toggle="tab"
                   aria-controls="home" role="tab" aria-selected="true">{{ tab }}</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>

  </div>

  <div class="card-body">
    <div class="row d-flex flex-wrap justify-content-between align-items-center">
      <TreeBrowser
          :style="{opacity: chartLoading ? .5 : 1}"
          v-for="(item, i) in accounts"
          :key="i"
          :node="item"
          :depth="0"
      />
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import TreeBrowser from "./TreeBrowser";
import TitleButton from '@/components/atom/TitleButton'
import handleInventory from "@/services/modules/inventory";

const emit = defineEmits(['handleTabChange'])
const {tabs, accounts} = defineProps(['tabs', 'accounts'])
const {chartLoading} = handleInventory();

let accountType = ref('item');
let accountTypeName = ref('Item');

function onClickTab(tab) {
  emit('handleTabChange', tab)
}

</script>